import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
    collection, 
    query, 
    getDocs, 
    where, 
    orderBy, 
    limit,
    increment,
    doc,
    setDoc
} from 'firebase/firestore';
import { db, trendingSearchesRef } from '../../firebaseConfig';
import { IconSearch, IconUserCircle, IconTrendingUp, IconBriefcase } from '@tabler/icons-react';
import './SearchComponent.css';

const SearchComponent = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [liveResults, setLiveResults] = useState([]);
    const [trendingSearches, setTrendingSearches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();

    // Fetch trending searches
    useEffect(() => {
        const fetchTrending = async () => {
            try {
                const q = query(trendingSearchesRef, orderBy('count', 'desc'), limit(5));
                const snapshot = await getDocs(q);
                const trending = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setTrendingSearches(trending);
            } catch (error) {
                console.error('Error fetching trending searches:', error);
            }
        };
        fetchTrending();
    }, []);

    // Handle live search with debounce
    useEffect(() => {
        const timer = setTimeout(async () => {
            if (searchQuery.trim().length >= 2) {
                setLoading(true);
                try {
                    const usersRef = collection(db, 'users');
                    const searchTerm = searchQuery.toLowerCase();
                    const q = query(
                        usersRef,
                        where('username_lower', '>=', searchTerm),
                        where('username_lower', '<=', searchTerm + '\uf8ff'),
                        limit(5)
                    );

                    const snapshot = await getDocs(q);
                    const results = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setLiveResults(results);
                    setShowDropdown(true);
                } catch (error) {
                    console.error('Error fetching live results:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLiveResults([]);
                setShowDropdown(searchQuery.length > 0);
            }
        }, 300);

        return () => clearTimeout(timer);
    }, [searchQuery]);

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const updateTrendingSearch = async (term) => {
        try {
            const docRef = doc(trendingSearchesRef, term.toLowerCase());
            await setDoc(docRef, {
                term: term,
                count: increment(1)
            }, { merge: true });
        } catch (error) {
            console.error('Error updating trending search:', error);
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            await updateTrendingSearch(searchQuery);
            navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
            setSearchQuery('');
            setShowDropdown(false);
        }
    };

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleCardClick = (userId) => {
        navigate(`/profile/${userId}`);
        setSearchQuery('');
        setShowDropdown(false);
    };

    return (
        <div className="search-container" ref={searchRef} role="search">
            <form onSubmit={handleSearch} className="search-form">
                <div className="search-input-wrapper">
                    <IconSearch className="search-icon" aria-hidden="true" />
                    <input
                        type="search"
                        id="search-input"
                        placeholder="Search users..."
                        value={searchQuery}
                        onChange={handleInputChange}
                        className="search-input"
                        aria-expanded={showDropdown}
                        aria-controls="search-dropdown"
                        aria-label="Search users"
                    />
                </div>
            </form>

            <AnimatePresence>
                {showDropdown && (
                    <motion.div
                        id="search-dropdown"
                        className="search-dropdown"
                        role="listbox"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                    >
                        {loading ? (
                            <div className="loading-indicator" role="status">
                                <div className="loading-spinner"></div>
                                <span>Searching...</span>
                            </div>
                        ) : (
                            <>
                                {liveResults.length > 0 ? (
                                    <div className="suggestions-list">
                                        {liveResults.map(user => (
                                            <motion.div
                                                key={user.id}
                                                className="suggestion-card"
                                                whileHover={{ scale: 1.02 }}
                                                role="option"
                                                onClick={() => handleCardClick(user.id)}
                                            >
                                                <div className="avatar">
                                                    {user.photoURL ? (
                                                        <img 
                                                            src={user.photoURL} 
                                                            alt={`${user.username || user.name}'s avatar`}
                                                            className="avatar-image"
                                                        />
                                                    ) : (
                                                        <IconUserCircle size={32} />
                                                    )}
                                                </div>
                                                <div className="user-info">
                                                    <h4 className="username">{user.username || user.name}</h4>
                                                    {user.profession && (
                                                        <p className="profession">
                                                            <IconBriefcase size={14} />
                                                            <span>{user.profession}</span>
                                                        </p>
                                                    )}
                                                </div>
                                            </motion.div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="trending-searches">
                                        <h3>
                                            <IconTrendingUp 
                                                size={16} 
                                                className="inline mr-2" 
                                                aria-hidden="true"
                                            />
                                            Trending Searches
                                        </h3>
                                        {trendingSearches.map((item) => (
                                            <div 
                                                key={item.id} 
                                                className="trending-item"
                                                role="option"
                                                onClick={() => {
                                                    setSearchQuery(item.term);
                                                    handleSearch({ preventDefault: () => {} });
                                                }}
                                            >
                                                {item.term}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default SearchComponent;