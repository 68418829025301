// Simple user avatar icon
export const defaultAvatars = [
    'https://via.placeholder.com/150/000000/FFFFFF/?text=User'
];

// Cover photos
export const defaultCovers = [
    { full: '/images/covers/alper-guzeler-BGTMCqnp9AI-unsplash.jpg' },
    { full: '/images/covers/coverpic3.jpg' },
    { full: '/images/covers/coverpic4.jpg' },
    { full: '/images/covers/elliott-engelmann-DjlKxYFJlTc-unsplash.jpg' }
]; 