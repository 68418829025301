import { useState } from 'react';
import { motion } from "framer-motion";
import { Mail, ArrowLeft, Loader } from "lucide-react";
import { Link } from 'react-router-dom';
import Input from './input.jsx';
import { validateEmail } from './utils/validation.js';
import { auth, sendPasswordResetEmail } from '../../firebaseConfig';

const ForgotPassword = ({ isDarkMode }) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [touchedFields, setTouchedFields] = useState({ email: false });

    const handleChange = (e) => {
        setEmail(e.target.value);
        if (touchedFields.email) {
            const emailValidation = validateEmail(e.target.value);
            setError(!emailValidation.isValid ? emailValidation.error : '');
        }
    };

    const handleBlur = () => {
        setTouchedFields({ email: true });
        const emailValidation = validateEmail(email);
        setError(!emailValidation.isValid ? emailValidation.error : '');
    };

    const isFormValid = () => {
        return validateEmail(email).isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) return;

        setIsLoading(true);
        try {
            await sendPasswordResetEmail(auth, email);
            setSuccess(true);
            setError('');
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`max-w-md w-full mx-auto ${
                isDarkMode 
                    ? 'bg-gray-800 text-white' 
                    : 'bg-white text-gray-900'
            } backdrop-filter backdrop-blur-xl rounded-2xl shadow-xl overflow-hidden p-4 sm:p-8`}
        >
            {/* Back to Login Link */}
            <Link 
                to="/login" 
                className={`flex items-center mb-6 ${
                    isDarkMode 
                        ? 'text-white hover:text-green-400' 
                        : 'text-gray-600 hover:text-green-500'
                } transition-colors`}
            >
                <ArrowLeft size={20} className="mr-2" />
                Back to Login
            </Link>

            <h2 className="text-3xl font-bold mb-2 text-center bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text">
                Reset Password
            </h2>
            <p className={`text-center mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-600'
            }`}>
                Enter your email address and we&apos;ll send you a link to reset your password.
            </p>

            {success ? (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={`p-4 ${
                        isDarkMode 
                            ? 'bg-green-500/20 border-green-500 text-green-400' 
                            : 'bg-green-500/10 border-green-500 text-green-600'
                    } border rounded-lg text-sm mb-4`}
                >
                    Reset link sent! Please check your email.
                </motion.div>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-1">
                        <Input
                            icon={Mail}
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={handleChange}
                            required
                            className={`${error ? 'border-red-500' : isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
                            aria-invalid={!!error}
                            onBlur={handleBlur}
                            autoComplete="email"
                            inputMode="email"
                        />
                        {touchedFields.email && error && (
                            <p className={`text-xs ${
                                isDarkMode ? 'text-red-400' : 'text-red-500'
                            } mt-1`}>
                                {error}
                            </p>
                        )}
                    </div>

                    <button
                        type="submit"
                        className={`w-full py-3 bg-gradient-to-r from-green-400 to-emerald-500 text-white rounded-lg 
                        font-semibold transition duration-200 
                        ${!isFormValid() || isLoading 
                            ? 'opacity-50 cursor-not-allowed hover:opacity-50' 
                            : 'hover:opacity-90 cursor-pointer'
                        }`}
                        disabled={!isFormValid() || isLoading}
                    >
                        {isLoading ? (
                            <Loader className="animate-spin mx-auto" />
                        ) : (
                            'Send Reset Link'
                        )}
                    </button>
                </form>
            )}
        </motion.div>
    );
};

export default ForgotPassword;
