// Feed.js
import React, { useState, useEffect } from 'react';
import './Feed.css';
import PostBox from './PostBox';
import Post from './Post';
import { 
    collection, 
    query, 
    getDocs, 
    where, 
    doc,
    getDoc,
    orderBy,
    onSnapshot
} from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { useAuth } from '../../contexts/AuthContext';
import { savePostToFirebase } from '../auth/utils/firebase/userOperations';

function Feed() {
    const [posts, setPosts] = useState([]);
    const { currentUser, userData } = useAuth();

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const userRef = doc(db, 'users', currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (!userSnap.exists()) {
                    console.error("User document does not exist");
                    setPosts([]);
                    return;
                }

                const userData = userSnap.data();
                const following = userData?.following || [];
                console.log("Following users:", following);

                if (Array.isArray(following) && following.length > 0) {
                    const postsRef = collection(db, 'posts');
                    
                    const followedUsersData = await Promise.all(
                        following.map(async (userId) => {
                            const userDoc = await getDoc(doc(db, 'users', userId));
                            return userDoc.exists() ? userDoc.data().username : null;
                        })
                    );

                    const validUsernames = followedUsersData.filter(username => username);

                    if (validUsernames.length > 0) {
                        console.log("Valid usernames for query:", validUsernames);
                        const q = query(
                            postsRef, 
                            where('username', 'in', validUsernames),
                            orderBy('timestamp', 'desc')
                        );
                        const postSnap = await getDocs(q);
                        const postList = postSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setPosts(postList);
                    } else {
                        setPosts([]);
                    }
                } else {
                    setPosts([]); 
                }
            } catch (error) {
                console.error("Error fetching posts:", error);
                setPosts([]);
            }
        };

        if (currentUser?.uid) {
            fetchPosts();
        }
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser?.uid) return;

        const postsRef = collection(db, 'posts');
        const q = query(postsRef, orderBy('timestamp', 'desc'));
        
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedPosts = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPosts(updatedPosts);
        });

        return () => unsubscribe();
    }, [currentUser]);

    const handlePost = async (content, actionTag) => {
        if (!currentUser || !userData) return;

        try {
            const newPost = {
                username: userData.username,
                profession: userData.profession || 'No profession set',
                content: content,
                timestamp: new Date().toISOString(),
                actionTag: actionTag.id || actionTag,
                id: Date.now().toString()
            };

            await savePostToFirebase(newPost);
            setPosts(prevPosts => [newPost, ...prevPosts]);
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    const handlePostUnsave = (postId) => {
        // Implementation of handlePostUnsave function
    };

    return (
        <div className="feed">
            {currentUser && userData && (
                <PostBox 
                    onPost={handlePost}
                    username={userData.username || 'User'}
                    profession={userData.profession || 'No profession set'}
                />
            )}
            <div className="feed__posts">
                {posts.map(post => (
                    <Post
                        key={post.id}
                        postId={post.id}
                        username={post.username}
                        profession={post.profession}
                        timestamp={post.timestamp}
                        content={post.content}
                        actionTag={post.actionTag}
                        avatar={post.avatar || null}
                        onUnsave={() => handlePostUnsave(post.id)}
                    />
                ))}
            </div>
        </div>
    );
}

export default Feed;