import React, { useState, useEffect } from 'react';
import { defaultCovers } from '../../../constants/defaultImages';
import { updateUserData, getUserDataWithPostCount } from '../../auth/utils/firebase/userOperations';
import { useAuth } from '../../../contexts/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditProfileModal from './EditProfileModal';
import ProfileHeader from './ProfileHeader';
import ProfileStats from './ProfileStats';
import ProfileVision from './ProfileVision';
import './UserProfile.css';

const UserProfile = () => {
    const { userData, currentUser, updateUserDataState } = useAuth();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: userData?.name || '',
        profession: userData?.profession || '',
        location: userData?.location || '',
        vision: userData?.vision || '',
        skills: userData?.skills || [],
    });
    
    const [tempFormData, setTempFormData] = useState(formData);
    const [showCoverOptions, setShowCoverOptions] = useState(false);
    const [tempCover, setTempCover] = useState(userData?.coverPhotoUrl || defaultCovers[0].full);
    const [currentCover, setCurrentCover] = useState(userData?.coverPhotoUrl || defaultCovers[0].full);
    const [postCount, setPostCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);

    useEffect(() => {
        if (userData) {
            setFormData({
                name: userData.name || '',
                profession: userData.profession || '',
                location: userData.location || '',
                vision: userData.vision || '',
                skills: userData.skills || [],
            });
            setCurrentCover(userData.coverPhotoUrl || defaultCovers[0].full);
            setTempCover(userData.coverPhotoUrl || defaultCovers[0].full);
        }
    }, [userData]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const updatedData = {
                ...tempFormData,
                coverPhotoUrl: tempCover
            };

            await updateUserData(currentUser.uid, updatedData);
            updateUserDataState(updatedData);
            setFormData(updatedData);
            setCurrentCover(tempCover);
            setIsPopupOpen(false);
            toast.success('Profile updated successfully!');
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error('Failed to update profile');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const data = await getUserDataWithPostCount(currentUser.uid);
                    if (data) {
                        setPostCount(data.postCount || 0);
                        setFollowingCount(data.following?.length || 0);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
    }, [currentUser]);

    const handleCloseModal = () => {
        setIsPopupOpen(false);
        setTempFormData(formData);
        setTempCover(currentCover);
        setShowCoverOptions(false);
    };

    return (
        <div className="user-profile-container">
            <ToastContainer />
            
            <div className="user-profile-content">
                <ProfileHeader 
                    currentCover={currentCover}
                    formData={formData}
                    onEditClick={() => setIsPopupOpen(true)}
                    userData={userData}
                />

                <ProfileStats 
                    postCount={postCount}
                    followingCount={followingCount}
                    userData={userData}
                />

                <ProfileVision 
                    vision={formData.vision}
                />

                <EditProfileModal 
                    isOpen={isPopupOpen}
                    onClose={handleCloseModal}
                    tempFormData={tempFormData}
                    setTempFormData={setTempFormData}
                    onSubmit={handleSubmit}
                    isLoading={isLoading}
                    showCoverOptions={showCoverOptions}
                    setShowCoverOptions={setShowCoverOptions}
                    tempCover={tempCover}
                    setTempCover={setTempCover}
                    defaultCovers={defaultCovers}
                />
            </div>
        </div>
    );
};

export default UserProfile; 