import React from 'react';
import { IconUserCircle, IconPencil } from '@tabler/icons-react';
import './ProfileHeader.css';

const ProfileHeader = ({ currentCover, formData, onEditClick, userData }) => {
    return (
        <div className="profile-header">
            <div className="cover-photo" style={{ backgroundImage: `url(${currentCover})` }}>
                <div className="profile-info-overlay">
                    <div className="avatar-container">
                        {userData?.photoURL ? (
                            <img src={userData.photoURL} alt="Profile" className="user-avatar" />
                        ) : (
                            <IconUserCircle size={100} className="user-avatar-icon" />
                        )}
                    </div>
                    <div className="user-info">
                        <h2>{formData.name || 'Anonymous User'}</h2>
                        <p className="profession">{formData.profession || 'No profession set'}</p>
                        <p className="location">{formData.location || 'Location not set'}</p>
                    </div>
                    <button className="edit-profile-btn" onClick={onEditClick}>
                        <IconPencil /> Edit Profile
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileHeader; 