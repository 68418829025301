import { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { Mail, Loader, CheckCircle } from "lucide-react";
import { Link } from 'react-router-dom';

const VerifyEmail = ({ isDarkMode }) => {
    const [isResending, setIsResending] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [email] = useState(() => localStorage.getItem('pendingVerificationEmail') || 'your email');

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const handleResendEmail = async () => {
        setIsResending(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 1500));
            setResendSuccess(true);
            setCountdown(60);
        } catch (error) {
            console.error('Failed to resend verification email:', error);
        } finally {
            setIsResending(false);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`max-w-md w-full mx-auto ${
                isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
            } backdrop-filter backdrop-blur-xl rounded-2xl shadow-xl overflow-hidden p-4 sm:p-8`}
        >
            <div className="text-center">
                <div className={`inline-flex items-center justify-center w-16 h-16 rounded-full ${
                    isDarkMode ? 'bg-green-500/20' : 'bg-green-100'
                } mb-6`}>
                    <Mail className="w-8 h-8 text-green-500" />
                </div>

                <h2 className="text-3xl font-bold mb-2 bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text">
                    Verify your email
                </h2>

                <p className={`mb-6 ${isDarkMode ? 'text-white' : 'text-gray-600'}`}>
                    We've sent a verification link to <br />
                    <span className="font-semibold">{email}</span>
                </p>

                {resendSuccess && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className={`flex items-center justify-center gap-2 p-4 mb-6 rounded-lg ${
                            isDarkMode 
                                ? 'bg-green-500/20 text-green-400' 
                                : 'bg-green-100 text-green-600'
                        }`}
                    >
                        <CheckCircle className="w-5 h-5" />
                        <span>Verification email resent!</span>
                    </motion.div>
                )}

                <div className="space-y-4">
                    <button
                        onClick={handleResendEmail}
                        disabled={isResending || countdown > 0}
                        className={`w-full py-3 rounded-lg font-semibold transition duration-200 
                            ${isDarkMode 
                                ? 'bg-white/10 hover:bg-white/20' 
                                : 'bg-gray-100 hover:bg-gray-200'
                            }
                            ${(isResending || countdown > 0) && 'opacity-50 cursor-not-allowed'}`}
                    >
                        {isResending ? (
                            <Loader className="animate-spin mx-auto" />
                        ) : countdown > 0 ? (
                            `Resend in ${countdown}s`
                        ) : (
                            'Resend verification email'
                        )}
                    </button>

                    <Link 
                        to="/login" 
                        className={`block text-center py-3 rounded-lg font-semibold 
                            ${isDarkMode 
                                ? 'text-white hover:text-green-400' 
                                : 'text-gray-600 hover:text-green-500'
                            } transition-colors`}
                    >
                        Back to login
                    </Link>
                </div>

                <div className="mt-8 text-sm text-center">
                    <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                        Didn't receive the email? Check your spam folder or contact{' '}
                        <a 
                            href="mailto:support@example.com" 
                            className="text-green-400 hover:text-green-300"
                        >
                            support
                        </a>
                    </p>
                </div>
            </div>
        </motion.div>
    );
};

export default VerifyEmail; 