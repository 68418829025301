import { db } from '../../../../firebaseConfig';
import { 
  doc, 
  setDoc, 
  getDoc, 
  updateDoc,
  collection,
  serverTimestamp,
  addDoc,
  query,
  where,
  getCountFromServer,
  getDocs 
} from 'firebase/firestore';

export const createUserDocument = async (user, additionalData) => {
    if (!user) return;

    try {
        const userRef = doc(db, 'users', user.uid);
        const snapshot = await getDoc(userRef);

        if (!snapshot.exists()) {
            const { email } = user;

            await setDoc(userRef, {
                username: additionalData.name,
                email,
                profession: '',
                following: [],
                createdAt: serverTimestamp(),
                lastLogin: serverTimestamp(),
                uid: user.uid
            });
        }

        const userData = await getDoc(userRef);
        console.log('User data retrieved:', userData.data());
        return userData.data();
    } catch (error) {
        console.error('Error creating user document:', error);
        throw error;
    }
};

export const getUserData = async (uid) => {
  if (!uid) throw new Error('No user ID provided');

  try {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      
      // Only query posts if username exists
      let postCount = 0;
      if (userData.username) {
        // Get post count
        const postsRef = collection(db, 'posts');
        const q = query(postsRef, where('username', '==', userData.username));
        const postCountSnapshot = await getCountFromServer(q);
        postCount = postCountSnapshot.data().count;
      }

      // Return combined data with safe defaults
      return { 
        id: userSnap.id, 
        ...userData,
        postCount,
        following: userData.following || [],
        followers: userData.followers || [],
        username: userData.username || userData.name || 'User', // Fallback for username
        profession: userData.profession || 'No profession set'
      };
    }
    
    console.log('No user document found for ID:', uid);
    return null;
  } catch (error) {
    console.error('Error in getUserData:', error);
    throw error;
  }
};

export const updateUserData = async (userId, userData) => {
    try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
            ...userData,
            skills: userData.skills || [], // Ensure skills are included
            updatedAt: serverTimestamp()
        });
        return true;
    } catch (error) {
        console.error('Error updating user data:', error);
        throw error;
    }
};

export const savePostToFirebase = async (post) => {
    try {
        if (!post.username || !post.content || !post.actionTag) {
            throw new Error('Missing required post fields');
        }

        const postsRef = collection(db, 'posts');
        const docRef = await addDoc(postsRef, {
            username: post.username,
            profession: post.profession || 'No profession set',
            content: post.content,
            timestamp: serverTimestamp(),
            actionTag: post.actionTag,
            id: post.id
        });

        console.log('Post saved with ID:', docRef.id);
        return docRef.id;
    } catch (error) {
        console.error('Error saving post:', error);
        throw error;
    }
};

export const getUserDataWithPostCount = async (uid) => {
    if (!uid) throw new Error('No user ID provided');

    try {
        const userRef = doc(db, 'users', uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            // Get user data
            const userData = userSnap.data();
            
            // Query to count posts for this user
            const postsRef = collection(db, 'posts');
            
            // Only proceed with query if we have a username
            if (userData?.username) {
                const q = query(postsRef, where('username', '==', userData.username));
                const postCountSnapshot = await getCountFromServer(q);
                const postCount = postCountSnapshot.data().count;

                return { 
                    id: userSnap.id, 
                    ...userData, 
                    postCount 
                };
            }
            
            // Return user data with 0 posts if no username
            return { 
                id: userSnap.id, 
                ...userData, 
                postCount: 0 
            };
        }
        
        console.log('No user document found for ID:', uid);
        return null;
    } catch (error) {
        console.error('Error in getUserDataWithPostCount:', error);
        throw error;
    }
};

export const updateExistingUsers = async () => {
    try {
        console.log('Starting update process...');
        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);
        
        console.log(`Found ${snapshot.docs.length} users to process`);
        
        for (const doc of snapshot.docs) {
            const userData = doc.data();
            console.log('Processing user document:', doc.id);
            
            // Check for either username or name field
            const nameToUse = userData.username || userData.name;
            
            if (nameToUse) {
                await updateDoc(doc.ref, {
                    username_lower: nameToUse.toLowerCase()
                });
                console.log(`Updated user: ${nameToUse}`);
            } else {
                console.log(`Skipping user ${doc.id} - no username or name found`);
            }
        }

        console.log('Finished updating all users');
        alert('Successfully updated all users!');
    } catch (error) {
        console.error('Error updating users:', error);
        alert('Error updating users: ' + error.message);
    }
};