import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getAuth, 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { getFirestore, collection, query, where, orderBy, limit, increment } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDeRlIoDwil4mNapoZeNl76QEoRZtjrUp8",
  authDomain: "wehearthem-9f5f3.firebaseapp.com",
  projectId: "wehearthem-9f5f3",
  messagingSenderId: "851136321240",
  appId: "1:851136321240:web:e4d7770f67830c247929ad",
  measurementId: "G-B8V67QSGGB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

// Add collections references
const usersRef = collection(db, 'users');
const trendingSearchesRef = collection(db, 'trending_searches');

export { 
  auth, 
  db,
  usersRef,
  trendingSearchesRef,
  googleProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPopup,
  increment // for updating trending searches count
};