import React, { useState, useEffect } from "react";
import { Sun, Moon } from 'lucide-react';

function AuthLayout({ children }) {
  const [shapes, setShapes] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const generateShapes = () => {
      return Array.from({ length: 20 }, () => ({
        type: Math.random() > 0.5 ? 'circle' : 'square',
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 60 + 40,
        rotation: Math.random() * 360,
        speed: Math.random() * 0.5 + 0.2
      }));
    };
    setShapes(generateShapes());
  }, []);

  const handleMouseMove = (e) => {
    setMousePosition({
      x: (e.clientX / window.innerWidth) * 2 - 1,
      y: (e.clientY / window.innerHeight) * 2 - 1
    });
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div 
      className={`min-h-screen relative overflow-hidden p-4 flex items-center justify-center
        ${isDarkMode 
          ? 'bg-gradient-to-br from-zinc-950 to-slate-950' 
          : 'bg-gradient-to-br from-slate-100 to-white'}`}
      onMouseMove={handleMouseMove}
    >
      {/* Theme Toggle Button */}
      <button
        onClick={toggleTheme}
        className={`absolute top-4 right-4 z-20 p-2 rounded-full transition-all duration-300
          ${isDarkMode 
            ? 'bg-white/10 hover:bg-white/20 text-white' 
            : 'bg-slate-200 hover:bg-slate-300 text-slate-700'}`}
      >
        {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
      </button>

      {/* Geometric constellations */}
      <div className="absolute inset-0">
        <svg className="w-full h-full absolute inset-0">
          {shapes.map((shape, i) => 
            shapes.slice(i + 1).map((otherShape, j) => {
              const distance = Math.hypot(shape.x - otherShape.x, shape.y - otherShape.y);
              if (distance < 40) {
                return (
                  <line
                    key={`${i}-${j}`}
                    x1={`${shape.x}%`}
                    y1={`${shape.y}%`}
                    x2={`${otherShape.x}%`}
                    y2={`${otherShape.y}%`}
                    stroke={isDarkMode ? "rgba(255,255,255,0.15)" : "rgba(0,0,0,0.1)"}
                    strokeWidth="1.5"
                    strokeDasharray="4 4"
                  />
                );
              }
              return null;
            })
          )}
        </svg>

        {shapes.map((shape, i) => (
          <div
            key={i}
            className={`absolute ${
              shape.type === 'circle' ? 'rounded-full' : 'rotate-45'
            } border-2 transition-colors duration-300 ${
              isDarkMode 
                ? 'bg-white/5 border-white/20' 
                : 'bg-black/5 border-black/10'
            }`}
            style={{
              left: `${shape.x}%`,
              top: `${shape.y}%`,
              width: `${shape.size}px`,
              height: `${shape.size}px`,
              transform: `
                rotate(${shape.rotation + mousePosition.x * 20}deg)
                translateX(${mousePosition.x * 20}px)
                translateY(${mousePosition.y * 20}px)
              `,
              transition: 'transform 0.3s ease-out, background-color 0.3s, border-color 0.3s',
            }}
          >
            <div className={`absolute inset-0 ${
              shape.type === 'circle' ? 'rounded-full' : ''
            } ${isDarkMode ? 'bg-white/5' : 'bg-black/5'} backdrop-blur-sm`} />
          </div>
        ))}
      </div>

      {/* Content container with adjusted blur */}
      <div className="relative z-10 w-full max-w-md">
        <div className={`absolute inset-0 rounded-2xl transition-colors duration-300 ${
          isDarkMode 
            ? 'bg-gray-900 bg-opacity-60' 
            : 'bg-white bg-opacity-30'
        } backdrop-blur-xl`}></div>
        <div className="relative">
          {/* Pass isDarkMode to children */}
          {React.cloneElement(children, { isDarkMode })}
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
