import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, query, getDocs, where, orderBy } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { IconUserCircle, IconMapPin, IconBriefcase } from '@tabler/icons-react';
import './SearchResults.css';

const SearchResults = () => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState('relevance'); // 'relevance' or 'alphabetical'
    const location = useLocation();
    const searchQuery = new URLSearchParams(location.search).get('q');

    useEffect(() => {
        const fetchResults = async () => {
            if (!searchQuery) return;
            
            setLoading(true);
            try {
                const usersRef = collection(db, 'users');
                const searchTerm = searchQuery.toLowerCase();
                const q = query(
                    usersRef,
                    where('username_lower', '>=', searchTerm),
                    where('username_lower', '<=', searchTerm + '\uf8ff')
                );

                const snapshot = await getDocs(q);
                let users = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    relevanceScore: calculateRelevance(doc.data().username_lower, searchTerm)
                }));

                // Sort results based on selected order
                users = sortResults(users, sortOrder);
                
                setResults(users);
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
    }, [searchQuery, sortOrder]);

    const calculateRelevance = (username, searchTerm) => {
        if (username === searchTerm) return 100; // Exact match
        if (username.startsWith(searchTerm)) return 75; // Starts with
        if (username.includes(searchTerm)) return 50; // Contains
        return 25; // Partial match
    };

    const sortResults = (users, order) => {
        return users.sort((a, b) => {
            if (order === 'relevance') {
                return b.relevanceScore - a.relevanceScore;
            }
            return a.username_lower.localeCompare(b.username_lower);
        });
    };

    const handleFollow = async (userId) => {
        // TODO: Implement follow functionality
        console.log('Following user:', userId);
    };

    return (
        <div className="search-results-container" role="main">
            {loading ? (
                <motion.div 
                    className="loader"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    role="status"
                >
                    <div className="loader-spinner"></div>
                    <span>Searching...</span>
                </motion.div>
            ) : (
                <div className="content-section">
                    <div className="search-header">
                        <h2>Search Results for "{searchQuery}"</h2>
                        <select
                            value={sortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                            className="sort-select"
                            aria-label="Sort results by"
                        >
                            <option value="relevance">Sort by Relevance</option>
                            <option value="alphabetical">Sort Alphabetically</option>
                        </select>
                    </div>

                    <AnimatePresence mode="wait">
                        {results.length > 0 ? (
                            <motion.div 
                                className="results-grid"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {results.map((user, index) => (
                                    <motion.div
                                        key={user.id}
                                        className="user-card"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: index * 0.1 }}
                                        whileHover={{ y: -5, transition: { duration: 0.2 } }}
                                    >
                                        <div className="user-card-content">
                                            <div className="user-avatar">
                                                {user.photoURL ? (
                                                    <img 
                                                        src={user.photoURL} 
                                                        alt={`${user.username || user.name}'s avatar`}
                                                        className="avatar-image"
                                                    />
                                                ) : (
                                                    <IconUserCircle className="avatar-icon" />
                                                )}
                                            </div>
                                            <div className="user-info">
                                                <h3>{user.username || user.name}</h3>
                                                {user.profession && (
                                                    <p className="user-profession">
                                                        <IconBriefcase size={16} />
                                                        {user.profession}
                                                    </p>
                                                )}
                                                {user.location && (
                                                    <p className="user-location">
                                                        <IconMapPin size={16} />
                                                        {user.location}
                                                    </p>
                                                )}
                                            </div>
                                            <button 
                                                className="follow-button"
                                                onClick={() => handleFollow(user.id)}
                                                aria-label={`Follow ${user.username || user.name}`}
                                            >
                                                Follow
                                            </button>
                                        </div>
                                    </motion.div>
                                ))}
                            </motion.div>
                        ) : (
                            <motion.div 
                                className="no-results"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                            >
                                No results found for "{searchQuery}"
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            )}
        </div>
    );
};

export default SearchResults; 