import React from 'react';
import './ProfileVision.css';

const ProfileVision = ({ vision }) => {
    return (
        <div className="vision_section">
            <h3>My Vision</h3>
            <p className='vision_text'>{vision || 'No vision statement added yet.'}</p>
        </div>
    );
};

export default ProfileVision; 