import React from 'react';
import { motion } from 'framer-motion';

const PrivacyPolicyPage = ({ isDarkMode }) => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.3 
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={sectionVariants}
      className={`min-h-screen py-12 px-4 sm:px-6 lg:px-8 ${isDarkMode ? 'bg-gray-900 text-gray-200' : 'bg-white text-gray-800'}`}
    >
      <div className="max-w-4xl mx-auto">
        <motion.h1 
          variants={itemVariants}
          className="text-4xl font-extrabold mb-8 text-center bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text"
        >
          Privacy Policy
        </motion.h1>

        <motion.div 
          variants={itemVariants}
          className={`space-y-6 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'} p-8 rounded-lg shadow-md`}
        >
          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">1. Information We Collect</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Personal information (name, email, profile picture)</li>
              <li>Usage data and interactions</li>
              <li>Device and location information</li>
            </ul>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">2. How We Use Your Information</h2>
            <p>We use your information to:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>Provide and improve our service</li>
              <li>Personalize user experience</li>
              <li>Communicate with you</li>
              <li>Monitor and analyze usage</li>
            </ul>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">3. Data Sharing</h2>
            <p>
              We do not sell your personal information. We may share data with:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Service providers and partners</li>
              <li>Legal requirements</li>
              <li>Protection of our rights</li>
            </ul>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">4. Your Rights</h2>
            <p>You have the right to:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>Access your personal data</li>
              <li>Request data deletion</li>
              <li>Opt-out of marketing communications</li>
              <li>Update your information</li>
            </ul>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">5. Security</h2>
            <p>
              We implement industry-standard security measures to protect your data, 
              including encryption and secure storage practices.
            </p>
          </motion.section>

          <motion.div 
            variants={itemVariants}
            className="text-center mt-8 border-t pt-6"
          >
            <p className="text-sm text-gray-500">
              Last Updated: {new Date().toLocaleDateString()}
            </p>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default PrivacyPolicyPage;