import React from 'react';
import { motion } from 'framer-motion';

const TermsOfServicePage = ({ isDarkMode }) => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.3 
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={sectionVariants}
      className={`min-h-screen py-12 px-4 sm:px-6 lg:px-8 ${isDarkMode ? 'bg-gray-900 text-gray-200' : 'bg-white text-gray-800'}`}
    >
      <div className="max-w-4xl mx-auto">
        <motion.h1 
          variants={itemVariants}
          className="text-4xl font-extrabold mb-8 text-center bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text"
        >
          Terms of Service
        </motion.h1>

        <motion.div 
          variants={itemVariants}
          className={`space-y-6 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'} p-8 rounded-lg shadow-md`}
        >
          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">1. Acceptance of Terms</h2>
            <p>
              By accessing and using our social media platform, you agree to be bound by these Terms of Service. 
              If you do not agree with any part of these terms, you may not use our service.
            </p>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">2. User Account</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>You must provide accurate and current information</li>
              <li>You are responsible for maintaining the confidentiality of your account</li>
              <li>You agree to accept responsibility for all activities under your account</li>
            </ul>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">3. User Content</h2>
            <p>
              You retain ownership of content you create, but grant us a worldwide, non-exclusive license 
              to use, reproduce, and distribute your content within the platform.
            </p>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">4. Prohibited Conduct</h2>
            <p>Users are prohibited from:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>Posting harmful, offensive, or illegal content</li>
              <li>Harassing or bullying other users</li>
              <li>Impersonating other individuals</li>
              <li>Sharing personal information without consent</li>
            </ul>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2 className="text-2xl font-bold mb-4 text-emerald-500">5. Termination</h2>
            <p>
              We reserve the right to suspend or terminate your account if you violate these Terms of Service, 
              without prior notice and at our sole discretion.
            </p>
          </motion.section>

          <motion.div 
            variants={itemVariants}
            className="text-center mt-8 border-t pt-6"
          >
            <p className="text-sm text-gray-500">
              Last Updated: {new Date().toLocaleDateString()}
            </p>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default TermsOfServicePage;