import React, { useState, useEffect } from 'react';
import { IconReplace, IconX, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { defaultCovers } from '../../../constants/defaultImages';
import './EditProfileModal.css';

const EditProfileModal = ({
    isOpen,
    onClose,
    tempFormData,
    setTempFormData,
    onSubmit,
    isLoading,
    showCoverOptions,
    setShowCoverOptions,
    tempCover,
    setTempCover,
}) => {
    const [countryOptions, setCountryOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState(tempFormData.location || '');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [skillInput, setSkillInput] = useState('');
    const [skills, setSkills] = useState(tempFormData.skills || []);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://restcountries.com/v3.1/all');
                if (!response.ok) throw new Error('Failed to fetch countries');
                const countries = await response.json();
                setCountryOptions(countries.map(country => country.name.common).sort());
            } catch (error) {
                console.error('Error fetching countries:', error);
                setError('Failed to load countries. Please try again later.');
                setCountryOptions([]);
            }
            setLoading(false);
        };

        fetchCountries();
    }, []);

    const fetchSkills = async (query) => {
        if (!query) return [];
        try {
            const response = await fetch(`https://api.dataatwork.org/v1/skills/autocomplete?contains=${query}`);
            if (!response.ok) throw new Error('Failed to fetch skills');
            const data = await response.json();
            return data.map(skill => skill.normalized_skill_name);
        } catch (error) {
            console.error('Error fetching skills:', error);
            return [];
        }
    };

    const handleSkillInputChange = async (e) => {
        const value = e.target.value;
        setSkillInput(value);
        if (value.length >= 2) {
            const skillsSuggestions = await fetchSkills(value);
            setSuggestions(skillsSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleAddSkill = (skill) => {
        const normalizedSkill = skill.trim();
        if (normalizedSkill && !skills.includes(normalizedSkill)) {
            const updatedSkills = [...skills, normalizedSkill];
            setSkills(updatedSkills);
            setTempFormData(prev => ({ ...prev, skills: updatedSkills }));
            setSkillInput('');
            setSuggestions([]);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && skillInput) {
            e.preventDefault();
            handleAddSkill(skillInput);
        }
    };

    const handleRemoveSkill = (skill) => {
        setSkills(prevSkills => prevSkills.filter(s => s !== skill));
        setTempFormData(prev => ({ ...prev, skills: prev.skills.filter(s => s !== skill) }));
    };

    const filteredOptions = countryOptions.filter(option =>
        option.toLowerCase().includes(inputValue.toLowerCase())
    );

    const handleCountrySelect = (country) => {
        setInputValue(country);
        setTempFormData(prev => ({ ...prev, location: country }));
        setOpen(false);
    };

    const handleClear = () => {
        setInputValue('');
        setTempFormData(prev => ({ ...prev, location: '' }));
    };

    const handleDropdownToggle = () => {
        setOpen(prev => !prev);
    };

    const handleInputFocus = () => {
        setOpen(true);
    };

    if (!isOpen) return null;

    const handleCoverSelect = (coverUrl) => {
        setTempCover(coverUrl);
        setShowCoverOptions(false);
    };

    return (
        <div className="edit-profile-overlay" onClick={onClose}>
            <div className="edit-profile-modal" onClick={(e) => e.stopPropagation()}>
                <div 
                    className="modal-cover-photo" 
                    style={{ 
                        backgroundImage: `url(${tempCover})`,
                    }}
                >
                    <button 
                        className="change-cover-btn"
                        onClick={() => setShowCoverOptions(true)}
                    >
                        <IconReplace size={24} />
                    </button>
                </div>

                <div className="modal-content">
                    {showCoverOptions && (
                        <div className="cover-options">
                            <div className="preset-covers">
                                {defaultCovers.map((cover, index) => (
                                    <div
                                        key={index}
                                        className={`preset-cover ${tempCover === cover.full ? 'selected' : ''}`}
                                        style={{ backgroundImage: `url(${cover.full})` }}
                                        onClick={() => handleCoverSelect(cover.full)}
                                    ></div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="form-sections">
                        <div className="form-section">
                            <h3>Basic Information</h3>
                            <div className="input-group">
                                <label>Name</label>
                                <input
                                    type="text"
                                    value={tempFormData.name}
                                    onChange={(e) => setTempFormData({ ...tempFormData, name: e.target.value })}
                                    placeholder="Enter your name"
                                />
                            </div>

                            <div className="input-group">
                                <label>Profession</label>
                                <input
                                    type="text"
                                    value={tempFormData.profession}
                                    onChange={(e) => setTempFormData({ ...tempFormData, profession: e.target.value })}
                                    placeholder="Enter your profession"
                                />
                            </div>

                            <div className="input-group">
                                <label>Location</label>
                                <div className="country-input-wrapper">
                                    <input
                                        type="text"
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        onFocus={handleInputFocus}
                                        placeholder=" "
                                        className="country-input"
                                    />
                                    <div className="country-input-icons">
                                        {inputValue && (
                                            <IconX 
                                                className="clear-icon"
                                                size={20}
                                                stroke={1.5}
                                                onClick={handleClear}
                                            />
                                        )}
                                        {open ? (
                                            <IconChevronUp 
                                                className="dropdown-icon"
                                                size={20}
                                                stroke={1.5}
                                                onClick={handleDropdownToggle}
                                            />
                                        ) : (
                                            <IconChevronDown 
                                                className="dropdown-icon"
                                                size={20}
                                                stroke={1.5}
                                                onClick={handleDropdownToggle}
                                            />
                                        )}
                                    </div>
                                </div>
                                {open && (
                                    <div className="country-options">
                                        {loading ? (
                                            <div className="loading">Loading...</div>
                                        ) : filteredOptions.length > 0 ? (
                                            filteredOptions.map((option, index) => (
                                                <div
                                                    key={index}
                                                    className="country-option"
                                                    onClick={() => handleCountrySelect(option)}
                                                >
                                                    {option}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="no-options">No countries found</div>
                                        )}
                                    </div>
                                )}
                                {error && <div className="error-message">{error}</div>}
                            </div>
                        </div>

                        <div className="form-section">
                            <h3>My Vision</h3>
                            <textarea
                                className="vision-input"
                                value={tempFormData.vision}
                                onChange={(e) => setTempFormData({ ...tempFormData, vision: e.target.value })}
                                placeholder="Share your vision..."
                            />
                        </div>

                        <div className="form-section">
                            <h3>Add Skills</h3>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={skillInput}
                                    onChange={handleSkillInputChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Type a skill and press Enter"
                                    className="skill-input"
                                />
                                {suggestions.length > 0 && (
                                    <div className="suggestions">
                                        {suggestions.map((skill, index) => (
                                            <div
                                                key={index}
                                                className="suggestion-item"
                                                onClick={() => handleAddSkill(skill)}
                                            >
                                                {skill}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="skills-list">
                                {skills.map((skill, index) => (
                                    <div key={index} className="skill-item">
                                        {skill}
                                        <IconX
                                            className="remove-skill"
                                            size={16}
                                            onClick={() => handleRemoveSkill(skill)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="button-container">
                        <button 
                            className="cancel" 
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button 
                            className="save" 
                            onClick={onSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProfileModal;