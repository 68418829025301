import React, { useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import './Sidebar.css';
import SidebarOption from './SidebarOption';
import { IconClipboardList, IconHeartHandshake, IconUsersGroup, IconFiles, IconLogout2, IconUserCircle } from "@tabler/icons-react";
import { auth } from '../../firebaseConfig';
import { useAuth } from '../../contexts/AuthContext';

const UserProfile = () => {
  const { userData } = useAuth();
  const location = useLocation();

  return (
    <div className="sidebar-profile">
      <NavLink 
        to="/profile" 
        className={({ isActive }) => `profile-link ${
          isActive && location.pathname === '/profile' ? 'active' : ''
        }`}
      >
        <div className="profile-content">
          <div 
            className="profile-avatar"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <IconUserCircle color="black" size={45} />
          </div>
          <div className="profile-info">
            <h3>{userData?.username || 'User'}</h3>
            <p>{userData?.profession || 'No profession set'}</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

const LogoutDialog = ({ isOpen, onClose, onConfirm, isDarkMode }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div 
        className={`modal-content ${
          isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
        } rounded-lg p-6 max-w-sm w-full shadow-xl`}
      >
        <h3 className="text-xl font-semibold mb-4">Confirm Logout</h3>
        <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          Are you sure you want to log out?
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className={`px-4 py-2 rounded-lg ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600' 
                : 'bg-gray-200 hover:bg-gray-300'
            } transition-colors`}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

function Sidebar({ isDarkMode }) {
  const navigate = useNavigate();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const handleLogoutClick = () => {
    setShowLogoutDialog(true);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      sessionStorage.setItem('logoutMessage', 'You have been successfully logged out.');
      setShowLogoutDialog(false);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      <div className="sidebar">
        <UserProfile />
        
        <div className="sidebar-menu">
          <div className="sidebarOption contributions coming-soon">
            <SidebarOption
              Icon={IconHeartHandshake}
              text="Contributions"
              iconColor="#4CAF50"
              comingSoon={true}
            />
          </div>
          <div className="sidebarOption followed-projects coming-soon">
            <SidebarOption
              Icon={IconUsersGroup}
              text="Followed Projects"
              iconColor="#2196F3"
              comingSoon={true}
            />
          </div>
          <div className="sidebarOption your-projects coming-soon">
            <SidebarOption
              Icon={IconFiles}
              text="Your Projects"
              iconColor="#F44336"
              comingSoon={true}
            />
          </div>
          <NavLink to="/files" className={({ isActive }) => `sidebarOption files ${isActive ? "sidebarOption--active" : ""}`}>
            <SidebarOption
              Icon={IconClipboardList}
              text="Files"
              iconColor="#FF9800"
            />
          </NavLink>
        </div>

        <div className="sidebar-logout">
          <div className="sidebarOption logout" onClick={handleLogoutClick}>
            <SidebarOption
              Icon={IconLogout2}
              text="Logout"
              iconColor="#666"
            />
          </div>
        </div>
      </div>

      <LogoutDialog
        isOpen={showLogoutDialog}
        onClose={() => setShowLogoutDialog(false)}
        onConfirm={handleLogout}
        isDarkMode={isDarkMode}
      />
    </>
  );
}

export default Sidebar;
