import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ScrollProvider } from './contexts/ScrollContext.js';
import Sidebar from "./pages/home/Sidebar.js";
import Header from "./pages/home/Header.js";
import Feed from './pages/home/Feed.js';
import SearchResults from './pages/home/SearchResults.js';
import Files from './pages/home/Files.js';
import UserProfile from './pages/home/UserProfile/UserProfile.js';
import UserProfile2 from './pages/home/UserProfile2.js';
import SignUpPage from './pages/auth/SignUpPage.jsx';
import LoginPage from './pages/auth/LoginPage.jsx';
import ForgotPassword from './pages/auth/ForgotPassword.jsx';
import AuthLayout from './pages/auth/AuthLayout.jsx';
import Notifications from './pages/home/Notifications.js';
import TermsOfServicePage from './pages/auth/TermsOfServicePage.jsx';
import PrivacyPolicyPage from './pages/auth/PrivacyPolicyPage.jsx';
import VerifyEmail from './pages/auth/VerifyEmail.jsx';
import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import { PrivateRoute } from './components/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Auth Routes */}
          <Route path="/" element={<Navigate to="/signup" />} />
          <Route path="/signup" element={<AuthLayout><SignUpPage /></AuthLayout>} />
          <Route path="/login" element={<AuthLayout><LoginPage /></AuthLayout>} />
          <Route path="/forgot-password" element={<AuthLayout><ForgotPassword /></AuthLayout>} />
          <Route path="/verify-email" element={<AuthLayout><VerifyEmail /></AuthLayout>} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          
          {/* Protected routes */}
          <Route path="/home" element={
            <PrivateRoute>
              <ScrollProvider>
                <div className="app-container">
                  <Sidebar />
                  <div className="main-content">
                    <Header />
                    <Feed />
                  </div>
                </div>
              </ScrollProvider>
            </PrivateRoute>
          } />

          {/* Add a redirect from /feed to /home */}
          <Route path="/feed" element={<Navigate to="/home" />} />

          {/* Add UserProfile route */}
          <Route path="/profile" element={
            <ScrollProvider>
              <div>
                <Sidebar />
                <div>
                  <Header />
                  <main>
                    <UserProfile />
                  </main>
                </div>
              </div>
            </ScrollProvider>
          } />

          {/* Files Route */}
          <Route path="/files" element={
            <ScrollProvider>
              <div>
                <Sidebar />
                <div>
                  <Header />
                  <main>
                    <Files />
                  </main>
                </div>
              </div>
            </ScrollProvider>
          } />

          {/* Search Route */}
          <Route path="/search" element={
            <ScrollProvider>
              <div>
                <Sidebar />
                <div>
                  <Header />
                  <main>
                    <SearchResults />
                  </main>
                </div>
              </div>
            </ScrollProvider>
          } />

          {/* UserProfile2 Route */}
          <Route path="/profile/:userId" element={
            <PrivateRoute>
              <ScrollProvider>
                <div>
                  <Sidebar />
                  <div>
                    <Header />
                    <main>
                      <UserProfile2 />
                    </main>
                  </div>
                </div>
              </ScrollProvider>
            </PrivateRoute>
          } />

          {/* Notifications Route */}
          <Route path="/notifications" element={
            <ScrollProvider>
              <div>
                <Sidebar />
                <div>
                  <Header />
                  <main>
                    <Notifications />
                  </main>
                </div>
              </div>
            </ScrollProvider>
          } />

          {/* Add this route protection */}
          <Route path="*" element={
            <Navigate to="/login" replace />
          } />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
      

