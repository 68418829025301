import React, { useState } from 'react';
import './ProfileStats.css';

const ProfileStats = ({ postCount, followingCount, userData }) => {
    const [showSkills, setShowSkills] = useState(false);

    return (
        <>
            <div className="stats-section">
                <div className="stat-item">
                    <span className="stat-number">{postCount}</span>
                    <span className="stat-label">Posts</span>
                </div>
                <div className="stat-item">
                    <span className="stat-number">{followingCount}</span>
                    <span className="stat-label">Following</span>
                </div>
                <div className="stat-item">
                    <span className="stat-number">{userData?.followers?.length || 0}</span>
                    <span className="stat-label">Followers</span>
                </div>
                <div 
                    className="stat-item" 
                    onClick={() => setShowSkills(!showSkills)}
                >
                    <span className="stat-number">{userData?.skills?.length || 0}</span>
                    <span className="stat-label">Skills</span>
                </div>
                <div className="stat-item with-tooltip">
                    <span className="stat-number">{userData?.projects?.length || 0}</span>
                    <span className="stat-label">Projects</span>
                    <span className="tooltip-text">This feature is under development</span>
                </div>
            </div>
            {showSkills && userData?.skills?.length > 0 && (
                <div className="skills-display">
                    <div className="skills-grid">
                        {userData.skills.map((skill, index) => (
                            <div key={index} className="skill-badge">
                                {skill}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProfileStats; 