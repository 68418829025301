import React, { useState } from 'react';
import './PostBox.css';
import { IconUserCircle } from '@tabler/icons-react';
import ReactDOM from 'react-dom';

const PostBox = ({ onPost, username, profession }) => {
    const [content, setContent] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [showActionTags, setShowActionTags] = useState(false);
    const [selectedActionTag, setSelectedActionTag] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (content.trim() && selectedActionTag) {
            onPost(content, selectedActionTag);
            setContent('');
            setSelectedActionTag(null);
            setShowPopup(false);
            setShowActionTags(false);
        }
    };

    const actionTags = [
        { label: 'Educate us', id: 'describe' },
        { label: 'Share your experience', id: 'experience' },
        { label: 'Ask for help', id: 'help' },
        { label: 'Suggest a solution', id: 'solution' },
    ];

    const getSelectedTagLabel = () => {
        const selectedTag = actionTags.find(tag => tag.id === selectedActionTag);
        return selectedTag ? selectedTag.label : '';
    };

    // Create portal modals
    const renderModals = () => {
        return ReactDOM.createPortal(
            <>
                {showActionTags && (
                    <div className="postbox__overlay">
                        <div className="postbox__modal tag-selection">
                            <div className="postbox__modal-header">
                                <h2>Select Action Tag</h2>
                            </div>
                            <div className="postbox__modal-content">
                                <ul className="postbox__tag-list">
                                    {actionTags.map((tag) => (
                                        <li key={tag.id}>
                                            <button
                                                className="postbox__tag-button"
                                                onClick={() => {
                                                    setSelectedActionTag(tag.id);
                                                    setShowActionTags(false);
                                                    setShowPopup(true);
                                                }}
                                            >
                                                {tag.label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="postbox__modal-footer">
                                <button
                                    className="postbox__cancel-button"
                                    onClick={() => setShowActionTags(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showPopup && (
                    <div className="postbox__overlay">
                        <div className="postbox__modal post-creation">
                            <div className="postbox__modal-content">
                                <div className="postbox__modal-header">
                                    <IconUserCircle className="postbox__avatar" />
                                    <div className="postbox__userInfo">
                                        <span className="postbox__username">{username}</span>
                                        <span className="postbox__profession">{profession}</span>
                                    </div>
                                </div>

                                <div className="postbox__selected-tag">
                                    {getSelectedTagLabel()}
                                    <button 
                                        className="postbox__change-tag"
                                        onClick={() => {
                                            setShowPopup(false);
                                            setShowActionTags(true);
                                        }}
                                    >
                                        Change
                                    </button>
                                </div>

                                <textarea
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    placeholder="What's on your mind?"
                                    className="postbox__textarea"
                                />

                                <div className="postbox__modal-footer">
                                    <button
                                        className="postbox__post-button"
                                        onClick={handleSubmit}
                                        disabled={!content.trim()}
                                    >
                                        Post
                                    </button>
                                    <button
                                        className="postbox__cancel-button"
                                        onClick={() => {
                                            setShowPopup(false);
                                            setContent('');
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>,
            document.body
        );
    };

    return (
        <>
            <div className="postbox">
                <div className="postbox__input-container">
                    <IconUserCircle className="postbox__avatar" />
                    <input
                        onClick={() => setShowActionTags(true)}
                        className="postbox__input"
                        placeholder="Select an Action Tag"
                        type="text"
                        readOnly
                        value=""
                    />
                </div>
            </div>
            {renderModals()}
        </>
    );
};

export default PostBox;
