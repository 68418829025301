import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './UserProfile2.css';
import { IconUserCircle, IconUserPlus, IconBriefcase, IconMapPin, IconCode } from '@tabler/icons-react';
import { Card, CardHeader, CardContent, Button, CircularProgress } from '@mui/material';
import { getUserData } from '../auth/utils/firebase/userOperations';

const UserProfile2 = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    
    const [isAddFriendPopupOpen, setIsAddFriendPopupOpen] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (!userId) {
                console.log('No userId available');
                return;
            }

            try {
                setLoading(true);
                const userData = await getUserData(userId);
                
                if (!userData) {
                    setError('User not found');
                    return;
                }

                setProfileData(userData);
            } catch (err) {
                console.error('Error fetching user profile:', err);
                setError('Error loading profile');
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [userId]);

    const handleAddFriendClick = () => {
        setIsAddFriendPopupOpen(true);
    };

    const handleCloseAddFriendPopup = () => {
        setIsAddFriendPopupOpen(false);
    };

    if (loading) {
        return (
            <div className="loading2-container">
                <CircularProgress size={60} style={{ color: '#31C65B' }} />
                <p>Loading profile...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error2-container">
                <h2>{error}</h2>
                <Button 
                    variant="contained" 
                    onClick={() => navigate('/home')}
                    style={{ 
                        background: 'linear-gradient(135deg, #31C65B, #2196F3)',
                        marginTop: '20px' 
                    }}
                >
                    Return Home
                </Button>
            </div>
        );
    }

    if (!profileData) return null;

    return (
        <div className="profile2-container">
            <div className="cover2-image"></div>
            <div className="profile2-content">
                <div className="profile2-header">
                    <div className="profile2-header-left">
                        <div className="user2-avatar">
                            {profileData.photoURL ? (
                                <img 
                                    src={profileData.photoURL} 
                                    alt={`${profileData.username}'s avatar`}
                                    className="avatar2-image"
                                />
                            ) : (
                                <IconUserCircle className='user2-avatar-icon' size={80} />
                            )}
                        </div>
                        <div className="user2-info">
                            <h2 className="user2-name">{profileData.username || profileData.name}</h2>
                            <div className="user2-details">
                                {profileData.profession && (
                                    <div className="detail2-item">
                                        <IconBriefcase size={24} />
                                        <span className="detail2-text">{profileData.profession}</span>
                                    </div>
                                )}
                                {profileData.location && (
                                    <div className="detail2-item">
                                        <IconMapPin size={24} />
                                        <span className="detail2-text">{profileData.location}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <button className="add2-friend-button" onClick={handleAddFriendClick}>
                        <IconUserPlus size={20} /> Add Friend
                    </button>
                </div>
                
                <div className="stats2-section">
                    <div className="stat2-box">
                        <span className="stat2-number">{profileData.postCount || 0}</span>
                        <span className="stat2-label">Posts</span>
                    </div>
                    <div className="stat2-box">
                        <span className="stat2-number">{profileData.following?.length || 0}</span>
                        <span className="stat2-label">Following</span>
                    </div>
                    <div className="stat2-box">
                        <span className="stat2-number">{profileData.followers?.length || 0}</span>
                        <span className="stat2-label">Followers</span>
                    </div>
                    <div className="stat2-box">
                        <span className="stat2-number">{profileData.skills?.length || 0}</span>
                        <span className="stat2-label">Skills</span>
                    </div>
                </div>
            </div>

            <div className="vision2-section">
                <h2 className="vision2-title">Vision</h2>
                <p className="vision2-text">
                    {profileData.vision || "This user hasn't shared their vision yet."}
                </p>
            </div>

            {profileData.skills && profileData.skills.length > 0 && (
                <div className="skills2-section">
                    <h2 className="skills2-title">
                        <IconCode size={24} />
                        Skills
                    </h2>
                    <div className="skills2-list">
                        {profileData.skills.map((skill, index) => (
                            <span key={index} className="skill2-tag">
                                {skill}
                            </span>
                        ))}
                    </div>
                </div>
            )}

            {/* Add Friend Popup */}
            {isAddFriendPopupOpen && (
                <div className="popup2-overlay">
                    <Card className="popup2-card">
                        <CardHeader
                            title="Add Friend"
                            action={
                                <Button onClick={handleCloseAddFriendPopup}>
                                    Close
                                </Button>
                            }
                        />
                        <CardContent>
                            <div className="popup2-content">
                                <IconUserCircle size={60} />
                                <p>Would you like to add {profileData.username || profileData.name} as a friend?</p>
                                <div className="popup2-buttons">
                                    <Button 
                                        variant="contained" 
                                        onClick={handleCloseAddFriendPopup}
                                        style={{ 
                                            background: 'linear-gradient(135deg, #31C65B, #2196F3)'
                                        }}
                                    >
                                        Add Friend
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        onClick={handleCloseAddFriendPopup}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
};

export default UserProfile2;